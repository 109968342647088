@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Tajawal:wght@700&family=Ubuntu:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&family=Raleway:wght@800&display=swap");

.App {
  color: white;
  text-align: center;
  font-family: "Inter", sans-serif;
  margin-right: 10px;
}

.App-text {
  background-color: #282c34;
  min-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
}

.inputField {
  width: 220px;
}

.input2 {
  background-color: white;
  width: 300px;
}

.input3 {
  background-color: #ffffff;
  width: calc(400px + 2vmin);
}

.input4 {
  background-color: #ffffff;
  width: calc(200px + 8vmin);
}

.input-reply {
  font-size: 13px;
  margin-botton: 14px;
}

.container2 {
  width: 500px - 2vw;
}

.App-text2 {
  background-color: #282c34;
  min-height: 20px;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  align-items: left;
  justify-content: left;
  text-align: left;
  font-size: calc(8px + 1vmin);
  padding: 0.3rem;
  color: white;
}

.App-text3 {
  background-color: #282c34;
  min-height: 20px;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: 15px;
  color: white;
}

.complaintButton {
  background-color: #282c34;
  min-height: 20px;
  width: 300px;

  display: inline-block;
  padding: 15px 32px;
  text-align: center;
  line-height: 1.5;
  font-size: 15px;
  color: white;
}

.App-title {
  background-color: #282c34;
  min-height: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: calc(25px + 4vmin);
  font-family: "Raleway", sans-serif;
  text-shadow: 6px 3px 4px rgba(0, 0, 0, 0.5);
  color: white;
}

.App-image img {
  width: calc(200px + 20.7vw);
  height: calc(85px + 9vw);
}

.App-image {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
}

.App-header3 {
  background-color: #282c34;
  min-height: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: calc(20px + 4vmin);
  font-family: "Raleway", sans-serif;
  text-shadow: 6px 3px 4px rgba(0, 0, 0, 0.5);
  color: white;
}

.App-header4 {
  background-color: #282c34;
  min-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  text-align: left;
  font-size: calc(15px + 2vmin);
  color: white;
}

.App-header2 {
  background-color: #282c34;
  min-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  text-align: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.resolveText {
  font-size: 18px;
  text-align: center;
  color: #26bb5d;
  margin-top: -10px;
  margin-bottom: 8px;
}

.comLine {
  border-radius: 3px;
  border: 1.5px solid #e0ac1c;
}

.comLine.res {
  border: 1.5px solid #31ac50;
}

.plugText {
  justify-content: center;
  text-align: center;
  color: rgb(244, 244, 244);
  position: absolute;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  bottom: 20px;
  left: 55px;
  text-decoration: none;
  border-bottom: #e0ac1c 0.125em solid;
  text-underline-offset: 3px;
}

.Submit-first {
  font-size: 20px;
  margin-top: 100px;
}

.textSub {
  background: white;
  width: 500px;
  margin-top: 10px;
  justify-content: space-between;
}

.App-header {
  background-color: #282c34;
  min-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  text-shadow: 6px 3px 4px rgba(0, 0, 0, 0.5);
  font-size: calc(20px + 2vmin);
  font-family: "Inter", sans-serif;
  color: white;
}

.App-link {
  color: #61dafb;
}

.avatarimage {
  border-radius: 4px;
}

.neg-margin {
  margin-bottom: -10px;
}

.boxButtons {
  text-align: right;
}

.Box-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.hover {
  transition: all 0.2s ease-in-out;
}
.hover:hover {
  transform: scale(1.2);
}

.logIn {
  padding: 5px;
}

.box {
  border-radius: 25px;
  border: 5px solid #e0ac1c;
  padding: 30px 30px 30px 30px;
  max-width: 500px;
  width: 500px;
}

.box.res {
  border: 5px solid #31ac50;
}

.box-subject {
  font-size: 21px;
  font-weight: 700 !important;
  text-align: left;
}

.box-description {
  font-size: 13px;
  text-align: left;
  min-height: 60px;
}

.box-user {
  font-size: 20px;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.reply-user {
  font-size: 12px;
  text-align: right;
  display: flex;
  flex-direction: row;
}

.box-title {
  font-size: 16px;
  text-align: left;
}

.box-right {
  font-size: 14px;
  text-align: right;
}

.box-right2 {
  font-size: 13px;
  text-align: right;
}

.box-right3 {
  font-size: 13px;
  text-align: center;
  margin-top: 4px;
  margin-left: 3px;
}

.test1 {
  display: inline-block;
  margin-right: 7px;
}

.test2 {
  display: inline-block;
}

.test3 {
  display: inline-block;
  color: #e0ac1c;
}

.likeDisplay {
  font-size: 20px;
  margin-top: 5px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
}

.comment-text {
  text-align: left;
  font-size: 12px;
  inline-size: 300px;
  overflow-wrap: break-word;
}

.grid-container2 {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 10px;
}

.grid-container5 {
  margin-top: 10px;
  margin-bottom: 23px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 10px;
}

.grid-container4 {
  margin-top: -2px;
  display: grid;
  grid-template-columns: 1.3fr 1.1fr 0.8fr;
  grid-gap: 3px;
  margin-bottom: -10px;
}

.gridmid {
  margin: 0 auto;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

textarea {
  color: rgb(24, 24, 26);
  font-size: 13px;
  width: 500px;
  font-family: "Ubuntu", sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-logo {
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* SWITCH AND SLIDER */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* BUTTONS */

button,
.button {
  color: #fdfeff;
  border: var(--custom-border);
  background-color: #e0ac1c;
  display: inline-block;
  text-align: center;
  border-radius: var(--custom-border-radius);
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-align: center;
  font-size: 0.9rem;
  text-transform: uppercase;
}

button.greenButton,
.greenButton {
  background-color: #5da030;
}
button.redButton,
.redButton {
  background-color: #971313;
}

button.resolve,
.resolve {
}
.resolve.res {
  background-color: #26bb5d;
}

button.likeButton,
.likeButton {
  background-color: #79bc4c;
  padding: 5px;
}

.likeButton.active {
  box-shadow: 0px 1px 13px -3px #79bc4c;
}

button.offButton,
.offButton {
  background-color: #848484;
  padding: 5px;
}

button.disButton,
.disButton {
  background-color: #c44040;
  padding: 5px;
}

.disButton.active {
  box-shadow: 0px 1px 13px -3px #c44040;
}

button.primary,
.button.primary {
  background-color: var(--custom-color-brand);
  border: 1px solid var(--custom-color-brand);
}

.dbIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  gap: 8px;
}

.warningText {
  color: white;
}

@media screen and (max-width: 600px) {
  .Box-center {
    padding-left: 140px;
  }

  .dbIcons {
    padding-left: 140px;
  }

  .App-image {
    padding-left: 140px;
  }

  .warningText {
    padding-left: 140px;
  }

  
}

@media screen and (max-height: 700px) {

  .plugText {
    visibility: hidden;
  }
}
