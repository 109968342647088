.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 320px;
  background-color: #21242b;

  &__logo img {
    margin-top: 40px;
    width: 200px;
    height: 180px;
  }

  &__logo {
    display: block;
    margin-left: 58px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: auto;
    display: flex;
    place-items: center;
    width: 140px;
    height: 170px;
  }

  &__menu {
    margin-top: 90px;
    position: relative;
    margin-left: 20px;
    margin-right: 20px;

    &__item:hover {
      font-weight: 900;
      text-shadow: 0 0 10px #ffffff;
      
    }

    &__item {
      display: flex;
      align-items: center;

      place-content: flex-start;
      padding: 1rem 3rem;
      font-size: 1.25rem;
      font-family: "Ubuntu", sans-serif;
      font-weight: 500;
      color: #ffffff;
      transition: color 0.3s ease-in-out;

      &.active {
        color: #21242b;
        border-radius: 15px;
        background-color: #e0ac1c;
      }

      &.active:hover {
        text-shadow: none;
      }

      &__icon {
        margin-right: 1rem;

        i {
          font-size: 1.75rem;
        }
      }
    }

    &__indicator {
      // position: absolute;
      // top: 0;
      // left: 50%;
      // width: calc(100% - 40px);
      // border-radius: 10px;
      // background-color: #e0ac1c;
      // z-index: -1;
      // transform: translateX(-50%);
      // transition: 0.3s ease-in-out;
    }
  }
}

@media screen and (max-height: 700px) {
  .sidebar__menu {
    margin-top: 20px;
  }

  .sidebar__logo {
    margin-top: 5px;
  }

  .sidebar__menu__item {
    padding-top: 13px;
    padding-bottom: 13px;
  }
}
