.sidebarmobile {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80px;
  background-color: #21242b;

  &__logo img {
    width: 70px;
    height: 60px;
    margin-top: 50px;
  }

  &__logo {
    display: block;
    margin-left: 6px;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-right: auto;
    display: flex;
    place-items: center;
    width: 160px;
    height: 120px;
  }

  &__menu {
    position: relative;
    margin-top: 80px;
    margin-left: 10px;
    margin-right: 10px;

    &__item:hover {
      font-weight: 900;
      text-shadow: 0 0 10px #ffffff;
    }

    &__item {
      display: flex;
      align-items: center;
      place-content: flex-start;
      padding: 1rem 1.6rem;
      padding-left: 17px;
      font-size: 1rem;
      font-family: Verdana;
      font-weight: 500;
      color: #ffffff;
      transition: color 0.3s ease-in-out;

      &.active {
        color: #21242b;
        border-radius: 10px;
        background-color: #e0ac1c;
      }

      &.active:hover {
        text-shadow: none;
      }

      &__icon {
        margin-right: 1rem;

        i {
          font-size: 1.75rem;
        }
      }
    }

    &__indicator {
      // position: absolute;
      // top: 0;
      // left: 50%;
      // width: calc(100% - 30px);
      // border-radius: 10px;
      // background-color: #e0ac1c;
      // z-index: -1;
      // transform: translateX(-50%);
      // transition: 0.3s ease-in-out;
    }
  }
}

@media screen and (max-height: 700px) {
  .sidebarmobile__menu {
    margin-top: 30px;
  }

  .sidebarmobile__logo {
    margin-top: 5px;
  }
}
