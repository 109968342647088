@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Tajawal:wght@700&family=Ubuntu:wght@300&display=swap");

html,
body {
  --custom-font-family: "Ubuntu", sans-serif;
  --custom-bg-color: #282c34;
  --custom-panel-color: #222;
  --custom-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.8);
  --custom-color: #fff;
  --custom-color-brand: #24b47e;
  --custom-color-secondary: #666;
  --custom-border: 1px solid #333;
  --custom-border-radius: 5px;
  --custom-spacing: 5px;

  padding: 0;
  margin: 0;
  font-family: var(--custom-font-family);
  background-color: var(--custom-bg-color);
}
